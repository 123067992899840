import { render, staticRenderFns } from "./SidebarPanelList.vue?vue&type=template&id=2bdd855c&scoped=true&"
import script from "./SidebarPanelList.vue?vue&type=script&lang=js&"
export * from "./SidebarPanelList.vue?vue&type=script&lang=js&"
import style0 from "./SidebarPanelList.vue?vue&type=style&index=0&id=2bdd855c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bdd855c",
  null
  
)

export default component.exports