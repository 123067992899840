<template>
  <div>
    <aside
      :class="{
      section: true,
      searchFocused,
      mobile: isMobile,
      hidden: menuHidden,
      desktop: !isMobile
      }"
      :style="`padding-left: 0px; ${statusMessage && !isMobile ? 'margin-top: 40px': ''}`"
    >
      <MobileUserMenu v-if="isMobile" class="mobile-menu-header" />
      <div v-else class="desktop-menu-header" :style="statusMessage ? 'top: 40px;': ''">
        <a style="padding-top: 2px; position: absolute; top: 0" @click="menuHidden = !menuHidden">
          <i :class="`mdi mdi-${ menuHidden ? 'menu' : 'backburger' } mdi-24px`" />
        </a>
        <span style="padding-left: 30px; margin-top: 2px; display: inline-block">
          {{ config.title }}
        </span>
        <UserButtons class="user-buttons"/>
      </div>
      <div class="menu-container">
        <!--<WorkgroupsDropdown />-->
        <b-input
          ref="panelSearch"
          v-model="panelSearch"
          :placeholder="i18n.search"
          type="text"
          name="panel-search"
          class="mainSearch"
          icon="magnify"
          @focus="searchFocused = true"
          @blur="searchFocused = false"
          @keydown.native="checkShortcuts"
          @keydown.up.native="inputKeyUp"
          @keydown.enter.native="goToFocusedPanel"
          @keydown.esc.native="blurSearch"
          @keydown.down.native="inputKeyDown"
        />
        <b-tabs type="is-boxed">
          <b-tab-item>
            <template slot="header">
              <i class="mdi mdi-file-tree" />
            </template>
            <ul class="menu-list">
              <li>
                <nuxt-link
                  to="/"
                  exact-active-class="is-active"
                >
                  <i class="mdi mdi-home" /> {{ i18n.home }}
                </nuxt-link>
              </li>
            </ul>
            <SidebarPanelList :panels="filteredPanels" :panel-search="panelSearch" :all-panels="panels.objects" />
          </b-tab-item>
          <b-tab-item>
            <template slot="header">
              <i class="mdi mdi-star" />
            </template>
            <SidebarPanelList :panels="filteredStarredPanels" :panel-search="panelSearch" :all-panels="panels.objects" />
            <div v-if="noStarredViews" style="padding: 20px; font-size: 75%">
              Aucune vue mise en favoris pour le moment. Vous pouvez ajouter une vue en favoris en
              appuyant sur l'icône <span class="tag"><i class="mdi mdi-star" /></span> à coté du nom d'une vue dans le menu.
            </div>
          </b-tab-item>
          <b-tab-item v-if="hasDashboards">
            <template slot="header">
              <i class="mdi mdi-table" />
            </template>
            <ul class="menu-list">
              <li v-for="d in dashboards.objects">
                <nuxt-link
                  :to="`/dashboard/${d._id}`"
                  :data-panel-name="d.title"
                  exact-active-class="is-active"
                >
                  {{d.title}}
                </nuxt-link>
              </li>
            </ul>
          </b-tab-item>
          <b-tab-item v-if="hasPages">
            <template slot="header">
              <i class="mdi mdi-file-document" />
            </template>
            <ul class="menu-list">
              <li v-for="d in pages.objects">
                <nuxt-link
                  :to="`/page/${d._id}`"
                  :data-panel-name="d.title"
                  exact-active-class="is-active"
                >
                  {{d.title}}
                </nuxt-link>
              </li>
            </ul>
          </b-tab-item>
        </b-tabs>
        <div v-if="PermissionManager.can(actions.can_see_add_panel_buttons)" class="additionnal-buttons">
          <button
            class="button is-info is-small is-fullwidth button-add-panel is-rounded"
            @click="$store.commit('config/setShowPanelFromTemplateModal', true)"
          >
            <i class="mdi mdi-plus" />
            {{ i18n.create }}
          </button>
        </div>
      </div>
    </aside>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { isMobile } from 'mobile-device-detect';

import Fuse from 'fuse.js';

import MobileUserMenu from '@/components/MobileUserMenu';
import PermissionManager from '@/permissions/PermissionManager';
import actions from '@/permissions/actions';
import WorkgroupsDropdown from '@/components/WorkgroupsDropdown';
import i18n from 'i18n/components/Sidebar.json';
import SidebarCategory from './SidebarCategory';
import SidebarPanelList from './SidebarPanelList';

import UserButtons from '@/components/UserButtons';

export default {
  name: 'Sidebar',
  components: {
    UserButtons,
    SidebarPanelList,
    SidebarCategory,
    WorkgroupsDropdown,
    MobileUserMenu,
  },
  data() {
    return {
      i18n,
      hasDashboards: process.env.VUE_APP_HAS_DASHBOARDS,
      hasPages: process.env.VUE_APP_HAS_PAGES,
      isMobile,
      actions,
      PermissionManager,
      fuse: undefined,
      searchFocused: false,
      fuseOptions: {
        keys: ['title'],
      },
      panelSearch: '',
      filteredPanels: [],
    };
  },
  computed: {
    ...mapState({
      config: (state) => state.config.config,
      statusMessage: (state) => state.statusMessages.statusMessage,
      user: (state) => state.login.user,
    }),
    menuHidden: {
      get() { return this.$store.state.config.menuHidden; },
      set(v) { this.$store.commit('config/setMenuHidden', v); },
    },
    panels: {
      get() {
        console.log('CP GET panels', this.$store.state.abstractElements.objects.panels);
        return this.$store.state.abstractElements.objects.panels;
      },
    },
    dashboards: {
      get() {
        return this.$store.state.abstractElements.objects.system_dashboards;
      },
    },
    pages: {
      get() {
        return this.$store.state.abstractElements.objects.system_pages;
      },
    },
    filteredStarredPanels () {
      const userInfo = this.$store.state.login.userDetails.additionnal_info;
      if (userInfo && userInfo.starredViews) {
        return this.filteredPanels.filter(p => userInfo.starredViews.indexOf(p._id) !== -1);
      } else {
        return [];
      }
    },
    noStarredViews () {
      return !this.$store.state.login.userDetails.additionnal_info ||
        !this.$store.state.login.userDetails.additionnal_info.starredViews ||
        !this.$store.state.login.userDetails.additionnal_info.starredViews.length;
    }
  },
  watch: {
    'panels.objects': {
      handler(v) {
        this.fuse = new Fuse(v, this.fuseOptions);
        this.panelSearch = '';
        this.filteredPanels = v;
      },
      deep: true,
      immediate: true,
    },
    panelSearch(v) {
      if (!v || v === '') {
        this.filteredPanels = this.panels.objects;
      } else {
        this.filteredPanels = this.fuse.search(v).map(i => i.item);
      }
    },
  },
  methods: {
    focusSearch() {
      this.$refs.panelSearch.focus();
    },
    blurSearch() {
      this.panelSearch = '';
      document.activeElement.blur();
    },
    checkShortcuts(event) {
      if (this.config.keyBindings && this.config.keyBindings.focusSearch) {
        if (event.key === this.config.keyBindings.focusSearch) {
          event.preventDefault();
          const nextInput = document.querySelector('.panelTitleBarSearch input');
          if (nextInput) {
            nextInput.select();
          }
        }
      }
    },
  },
};
</script>
<style scoped>
aside {
  background: whitesmoke;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  padding-top: 0;
  width: 300px;
  padding-right: 0;
  transition: margin-left 0.2s;
}
.scrollbar {
  padding-right: 20px;
  height: calc(100vh - 42px);
  overflow-y: auto;
  padding-top: 8px;
  /*padding-bottom: 100px;*/
}
.mobile .scrollbar {
  position: absolute;
  left: 0;
  width: 100%;
  top: 132px;
  height: calc(100vh - 132px);
  padding-bottom: 20px;
}
.searchFocused {
  background: #eee;
  transition: background 0.3s;
}
.menu-list li {
  padding-left: 24px;
}
.additionnal-buttons {
  margin-left: 12px;
  position: absolute;
  bottom: 78px;
  right: 4px;
}
.mobile-menu-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 132px;
}

.desktop {
  padding-top: 41px;
}

.mobile {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.desktop-menu-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  background: whitesmoke;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  z-index: 10;
  height: 40px;
  padding: 6px;
  font-weight: bold;
}
.desktop-menu-header .user-buttons {
  position: fixed;
  right: calc(100vw - 300px);
}

aside.hidden {
  margin-left: -294px;
}
aside.hidden:hover {
  margin-left: 0;
}
.mainSearch >>> input {
  background: whitesmoke;
}
.desktop  >>> .tab-content {
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 126px);
}

.desktop >>> .tabs > ul {
  background: #ddd;
  padding-left: 10px;
  padding-bottom: 10px;
}
.mobile >>> .tabs > ul {
  display: none;
}
.mobile >>> .menu-container {
  margin-top: 140px;
}
.desktop >>> nav.tabs a {
  border-radius: 0 0 8px 8px;
  background: #ddd;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 4px;
  padding-top: 4px;
}
.desktop >>> nav.tabs li.is-active a {
  background: whitesmoke;
  border-top: 1px solid whitesmoke;
}
aside >>> .b-tabs {
  display: flex;
  flex-direction: column;
}
aside >>> .b-tabs > .tab-content {
  order: 1;
}
aside >>> .b-tabs > .tabs {
  order: 2;
  width: 100%;
  height: 44px;
}
.mobile >>> .additionnal-buttons {
  display: none;
}
.menu-hidden .additionnal-buttons {
  bottom: 6px;
}
</style>
<style>
.has-status-message .desktop .tab-content {
  height: calc(100vh - 166px);
}
</style>
