<template>
  <Modal :active="active" class="see-as-modal" @close="$emit('close')">
    <template slot="header">
      Fonctionnalités expérimentales
    </template>
    <vue-form-generator
      :schema="schema"
      :model="model"
      tag="div"
    />
  </Modal>
</template>
<script>
import Modal from '@/components/modals/Modal';
import { mapState } from 'vuex';
import Api from '@/core/Api';

export default {
  components: {
    Modal,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      schema: {
        fields: [{
          type: 'checkbox',
          label: 'Pagination côté serveur',
          model: 'backendPagination',
        }]
      }
    };
  },
  computed: {
    ...mapState({
      model: (state) => state.experimentalFeatures.experimentalFeatures,
    }),
  },
  watch: {
    model: {
      handler (v) {
        this.$store.commit('experimentalFeatures/save', v);
      },
      deep: true
    }
  }
};
</script>
<style scoped>

</style>
