<template>
  <span class="workgroups-dropdown">
    <p v-if="userDetails" class="control">
      <label
        v-if="userDetails.workgroups && userDetails.workgroups.length"
      >
        {{ i18n.workgroup }}
      </label>
      <b-dropdown
        v-if="userDetails.workgroups && userDetails.workgroups.length"
        v-model="selectedWorkGroup"
        size="is-small"
        position="is-bottom-left"
        style="width: 100%"
        @change="changeWorkgroup"
      >
        <button slot="trigger" class="button">
          <i class="mdi mdi-domain" />
          {{ currentWorkgroup }}
          <i class="mdi mdi-menu-down" />
        </button>
        <b-dropdown-item
          v-for="g in userDetails.workgroups"
          :key="g"
          :value="g"
        >
          {{ g }}
        </b-dropdown-item>
      </b-dropdown>
    </p>
  </span>
</template>
<script>
import { mapState } from 'vuex';
import i18n from 'i18n/components/WorkgroupsDropdown.json';
import redirect from '@/core/utils/redirect';

export default {
  name: 'WorkgroupsDropdown',
  data() {
    return {
      i18n,
      selectedWorkGroup: undefined,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.login.user,
      userDetails: (state) => state.login.userDetails,
      currentWorkgroup: (state) => state.login.currentWorkgroup,
    }),
  },
  watch: {
    currentWorkgroup: {
      handler(wg) {
        this.selectedWorkGroup = wg;
      },
      immediate: true,
    },
  },
  methods: {
    changeWorkgroup(v) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify({ ...this.$route.query, workgroup: v })) {
        redirect(this.$router, [{ query: { ...this.$route.query, workgroup: v } }]);
        this.$store.commit('login/changeWorkgroup', v);
      }
    },
  },
};
</script>
<style scoped>
.control {
  position: relative;
}
.control >>> .dropdown-trigger, .control >>> .button {
  width: 100%;
  text-align: left;
  display: inline-block;
}
.mdi-menu-down {
  float: right;
}
.control >>> label {
  position: absolute;
  top: -7px;
  left: 10px;
  z-index: 1;
  text-transform: uppercase;
  font-size: 10px;
  background: white;
  border-radius: 4px;
}
</style>
