<template>
  <div>
    <SidebarCategory>
      <ul class="menu-list">
        <li
          v-for="p in sortedAllPanels.undefined"
          :key="p.panel._id"
        >
          <nuxt-link
            :to="`/panel/${p.panel._id}`"
            :data-panel-name="p.panel.title"
            exact-active-class="is-active"
          >
            <i v-if="p.panel.icon" :class="`mdi mdi-${p.panel.icon}`" />
            <i v-else class="mdi mdi-table" />
            <span v-if="p.panel.title">{{ p.panel.title }}</span>
            <span v-else style="font-style:italic">&lt;Untitled panel&gt;</span>
          </nuxt-link>
          <a :class="`star-link ${isViewStarred(p.panel._id) ? ' starred' : ''}
            ${loadingIndicators[p.panel._id] ? 'is-loading' : ''}`" @click="starView(p.panel._id)">
            <i class="mdi mdi-star" />
          </a>
        </li>
      </ul>
    </SidebarCategory>
    <div v-for="(panelsInGroup, categoryName) in sortedAllPanels" :key="categoryName">
      <SidebarCategory v-if="categoryName !== 'undefined'" :title="categoryName">
        <template slot="indicators">
          <span class="tag">
            {{ sortedPanels[categoryName] ? sortedPanels[categoryName].length : 0 }}
            <span v-if="panelSearch">
              /
              {{ sortedAllPanels[categoryName].length }}
            </span>
          </span>
        </template>
        <ul class="menu-list">
          <li
            v-for="p in sortedPanels[categoryName]"
            :key="p.panel._id"
          >
            <nuxt-link
              :to="`/panel/${p.panel._id}`"
              :data-panel-name="p.panel.title"
              exact-active-class="is-active"
            >
              <i v-if="p.panel.icon" :class="`mdi mdi-${p.panel.icon}`" />
              <i v-else class="mdi mdi-table" />
              <span v-if="p.panel.title">{{ p.panel.title }}</span>
              <span v-else style="font-style:italic">&lt;Untitled panel&gt;</span>
            </nuxt-link>
            <a :class="`star-link ${isViewStarred(p.panel._id) ? ' starred' : ''}
              ${loadingIndicators[p.panel._id] ? 'is-loading' : ''}`" @click="starView(p.panel._id)">
              <i class="mdi mdi-star" />
            </a>
          </li>
        </ul>
      </SidebarCategory>
    </div>
  </div>
</template>
<script>
import SidebarCategory from '@/components/SidebarCategory';

export default {
  components: {
    SidebarCategory,
  },
  props: {
    panels: {
      type: Array,
      required: true,
    },
    allPanels: {
      type: Array,
      required: true,
    },
    panelSearch: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sortKey: 'category',
      sortedPanels: {},
      sortedAllPanels: {},
      loadingIndicators: {},
    };
  },
  watch: {
    panels: {
      handler(v) {
        this.sortPanels(v, 'sortedPanels');
        this.sortedAllPanels = this.sortedPanels;
      },
      immediate: true,
      deep: true,
    },
    allPanels: {
      handler(v) {
        this.sortPanels(v, 'sortedAllPanels');
      },
      deep: true,
    },
  },

  methods: {
    isViewStarred (viewId) {
      const userInfo = this.$store.state.login.userDetails.additionnal_info;
      if (userInfo && userInfo.starredViews && userInfo.starredViews.indexOf(viewId) !== -1) {
        return true;
      }
      return false;
    },
    sortPanels(panels, storageKey) {
      const res = {};
      if (panels) {
        for (let i = 0; i < panels.length; i++) {
          if (res[panels[i][this.sortKey]] === undefined) {
            res[panels[i][this.sortKey]] = [];
          }
          res[panels[i][this.sortKey]].push({ panel: panels[i] });
        }

        const resKeys = Object.keys(res);
        let index = 0;
        for (let j = 0; j < resKeys.length; j++) {
          const array = res[resKeys[j]];
          for (let k = 0; k < array.length; k++) {
            array[k].index = index;
            index += 1;
          }
        }
      }
      this[storageKey] = res;
    },
    starView(viewId) {
      this.loadingIndicators[viewId] = true;
      this.$store.dispatch('panels/toggleViewStar', viewId);
      delete this.loadingIndicators[viewId];
    }
  },
};
</script>
<style scoped>
.menu-label {
  padding-top: 9px;
  padding-bottom: 9px;
  margin: 0;
}

.menu-list li {
  padding-left: 24px;
  position: relative;
}

.menu-list >>> a {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.star-link {
  position: absolute;
  left: -40px;
  top: 0px;
  padding-top: 10px;
  height: 35px;
  transition: left 0.2s;
  font-size: 75%;
  color: #888;
  padding-left: 6px;
  width: 24px;
  border-radius: 0;
}

.menu-list li:hover .star-link, .star-link.starred {
  left: 0;
}
.star-link.starred {
  background: #ffdd57aa;
  color: #444444;
}
.star-link:hover {
}
</style>
