<template>
  <div>
    <p v-if="title" class="menu-label" @click="opened = !opened">
      <i :class="`mdi mdi-menu-${opened ? 'down' : 'right'}`" />
      {{ title }}
      <span class="indicators"><slot name="indicators" /></span>
    </p>
    <div v-show="opened" class="category-content">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return { opened: true };
  },
};
</script>
<style>
.menu-label {
  margin-top: 0px;
  margin-bottom: 0px !important;
  padding: 6px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.menu-label:hover {
  text-decoration: underline;
  color: #444 !important;
}
</style>
