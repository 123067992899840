<template>
  <div class="mobile-user-menu">
    <button class="button logout-button" @click="logout">
      {{ i18n.logout }}
    </button>
    <div class="account-circle">
      <span style="display: block; margin-top: -11px">
        <i class="mdi mdi-account mdi-48px" />
      </span>
    </div>
    <div class="username">
      <UserButtons style="display: inline" />
      <!--
      <span>{{ user ? user.username : i18n.not_connected }}</span>
      -->
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import i18n from 'i18n/components/MobileUserMenu.json';
import UserButtons from '@/components/UserButtons';
import redirect from '@/core/utils/redirect';

export default {
  name: 'MobileUserMenu',
  components: {
    UserButtons
  },
  computed: {
    ...mapState({
      user: (state) => state.login.user,
    }),
  },
  data() {
    return { i18n };
  },
  methods: {
    logout() {
      this.$store.dispatch('login/logout');
      redirect(this.$router, ['/login']);
    },
  },
};
</script>
<style scoped>
.mobile-user-menu {
  background: linear-gradient(90deg, var(--primary-color-lighter) 0%, var(--primary-color-darker) 100%);
  padding: 20px;
}
.logout-button {
  float: right;
}
.username {
  position: absolute;
  top: 80px;
  left: 10px;
  padding-top: 2px;
  padding-left: 2px;
  font-weight: bold;
  font-size: 120%;
  color: white;
}
.account-circle {
  position: absolute;
  top: 16px;
  background: white;
  display: inline-block;
  border-radius: 100%;
  width: 54px;
  height: 52px;
  padding-top: 2px;
  text-align: center;
}
.logout-button {
  background: var(--primary-color-darker);
  border: 1px solid #00000090;
  color: white;
}
.mobile-user-menu >>> .main-menu-button {
  background-color: none;
}
</style>
