<template>
  <Modal :active="active" class="see-as-modal" @close="$emit('close')">
    <template slot="header">
      {{ i18n.see_as }}
    </template>
    <div v-if="userListLoading">
      Loading...
    </div>
    <div v-else class="field has-addons">
      <p class="control">
        <b-dropdown max-height="400px" append-to-body>
          <button slot="trigger" class="button">
            <i class="mdi mdi-account" />
            <span v-if="!selectedUser">
              {{ i18n.select_a_user }}
            </span>
            <span v-else>
              {{ selectedUser.username }} ({{ selectedUser.email }})
            </span>
            <i class="mdi mdi-menu-down" style="float: right" />
          </button>
          <div class="see-as-modal-dropdown-content">
            <b-dropdown-item v-for="u in userList" :key="u.username" @click="selectedUser = u">
              {{ u.username }} ({{ u.email }})
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </p>
      <p class="control">
        <button class="button is-primary" :disabled="!selectedUser" @click="applyUserScope">
          Voir
        </button>
      </p>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/components/modals/Modal';
import Api from '@/core/Api';
import i18n from 'i18n/components/modals/SeeAsModal.json';

export default {
  components: {
    Modal,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      i18n,
      selectedUser: undefined,
      userListLoading: false,
      userList: [],
    };
  },
  watch: {
    async active(v) {
      console.log('active watcher', v);
      if (v) {
        this.userListLoading = true;
        const res = await Api.get('/users');
        this.userList = res.data.documents;
        this.userListLoading = false;
      }
    },
  },
  methods: {
    applyUserScope() {
      this.$store.commit('login/setSeeAsUser', this.selectedUser);
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
.see-as-modal-dropdown-content {
  max-height: 300px;
  overflow-y: auto;
}
</style>
