<template>
  <span class="user-buttons">
    <b-dropdown>
      <button slot="trigger" :class="!isMobile ? 'button is-primary main-menu-button' : 'button is-light is-outlined'">
        {{ user ? user.username : 'not connected' }}
        <span v-if="seeAsUser">
          (as {{ seeAsUser.username }})
        </span>
        <div v-if="!isMobile">
          <span style="">
            <i class="mdi mdi-account mdi-24px" />
          </span>
        </div>
        <i class="mdi mdi-chevron-down" v-else />
      </button>
      <b-dropdown-item class="documentation-dropdown-item" @click="redirect($router, '/documentation')">
        <i class="mdi mdi-book" />
        {{ i18n.documentation }}
      </b-dropdown-item>
      <b-dropdown-item class="personnal-info-dropdown-item" @click="changePasswordModalActive = true">
        <i class="mdi mdi-account-edit" />
        {{ i18n.change_personnal_info }}
      </b-dropdown-item>
       <b-dropdown-item @click="experimentalFeaturesModalActive = !experimentalFeaturesModalActive">
        <i class="mdi mdi-test-tube" />
        Fonctionnalités expérimentales
      </b-dropdown-item>
      <b-dropdown-item v-if="PermissionManager.can(actions.can_see_see_as_mode)" @click="seeAsModalActive = true">
        <i class="mdi mdi-eye" />
        {{ i18n.see_as }}
      </b-dropdown-item>
      <b-dropdown-item v-if="seeAsUser" class="stop-seeing-as-user-button" @click="stopSeeingAsUser">
        <i class="mdi mdi-account" />
        {{ i18n.stop_seeing_as }}
        {{ seeAsUser.username }}
      </b-dropdown-item>
      <b-dropdown-item @click="errorReporting = !errorReporting">
        <label class="checkbox" style="pointer-events: none">
          <i class="mdi mdi-bug" />
          <input v-model="errorReporting" type="checkbox">
          {{ i18n.error_reporting }}
        </label>
      </b-dropdown-item>
      <b-dropdown-item class="logout-dropdown-item" @click="logout">
        <i class="mdi mdi-logout" />
        {{ i18n.logout }}
      </b-dropdown-item>
      <div class="dropdown-footer">
        <b>Dashy</b> - Dernière mise à jour: {{VUE_APP_LAST_BUILD ? new Date(VUE_APP_LAST_BUILD).toLocaleString(): 'Non défini'}}<br/>
        <span v-if="VUE_APP_LAST_BUILD" style="opacity: 0.5">
          (<timeago :datetime="VUE_APP_LAST_BUILD" />)
        </span>

      </div>
    </b-dropdown>
    <portal to="user-buttons-modals">
      <ExperimentalFeaturesModal :active="experimentalFeaturesModalActive" @close="experimentalFeaturesModalActive = false" />
      <SeeAsModal :active="seeAsModalActive" @close="seeAsModalActive = false" />
      <Modal :active="changePasswordModalActive" :width="1400" class="edit-password-modal" @close="changePasswordModalActive = false">
        <!--<template slot="header">
          {{ i18n.change_personnal_info }}
        </template>
        <b-loading :is-full-page="false" :active="loading" :can-cancel="false" class="loading"
        />
        <b-message v-if="error" type="is-danger" has-icon>
          {{ error }}
        </b-message>
        -->
        <b-tabs>
          <b-tab-item :label="i18n.change_password">
            <table>
              <tr>
                <td class="label">
                  {{ i18n.old_password }}
                </td>
                <td>
                  <input v-model="oldPassword" type="password" />
                </td>
              </tr>
              <tr>
                <td class="label">
                  {{ i18n.new_password }}
                </td>
                <td>
                  <input v-model="newPassword1" type="password" />
                </td>
              </tr>
              <tr>
                <td class="label">
                  {{ i18n.repeat_the_new_password }}
                </td>
                <td>
                  <input v-model="newPassword2" type="password" />
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  <button :disabled="oldPassword === '' || newPassword1 === '' || newPassword2 === ''" class="button is-primary change-password-button" @click="changePassword">
                    <i class="mdi mdi-key" />
                    {{ i18n.change_password }}
                  </button>
                </td>
              </tr>
            </table>
          </b-tab-item>
          <b-tab-item v-if="config.additionnalUserInfo" :label="i18n.additionnal_user_info">
            <vue-form-generator
              ref="form"
              :schema="{ fields: config.additionnalUserInfo }"
              :model="additionnalUserInfo"
              class="form"
              tag="div"
            />
            <button class="button is-primary" @click="saveUserInfo">
              {{ i18n.save }}
            </button>
          </b-tab-item>
        </b-tabs>
        <button class="button close-button" style="float: right" @click="changePasswordModalActive = false">
          {{ i18n.close }}
        </button>
      </Modal>
      </portal>
  </span>
</template>
<script>
import { ToastProgrammatic as Toast } from 'buefy';

import PermissionManager from '@/permissions/PermissionManager';
import actions from '@/permissions/actions';

import { mapState } from 'vuex';
import Modal from '@/components/modals/Modal';
import SeeAsModal from '@/components/modals/SeeAsModal';
import ExperimentalFeaturesModal from '@/components/modals/ExperimentalFeaturesModal';
import deepClone from '@/core/utils/deepClone';
import Api from '@/core/Api';
import i18n from 'i18n/components/UserButtons.json';
import redirect from '@/core/utils/redirect';

import { isMobile } from 'mobile-device-detect';

export default {
  name: 'UserButtons',
  components: {
    Modal,
    SeeAsModal,
    ExperimentalFeaturesModal
  },
  data() {
    setTimeout(() => {
      if (this.user === undefined) {
        redirect(this.$router, ['/login']);
      } else {
        console.log('user', this.user);
      }
    }, 2000);
    return {
      redirect,
      isMobile,
      i18n,
      loading: false,
      actions,
      PermissionManager,
      experimentalFeaturesModalActive: false,
      error: '',
      oldPassword: '',
      newPassword1: '',
      newPassword2: '',
      changePasswordModalActive: false,
      seeAsModalActive: false,
      additionnalUserInfo: deepClone(this.$store.state.login.userDetails.additionnal_info || {}),
      VUE_APP_LAST_BUILD: process.env.VUE_APP_LAST_BUILD,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.login.user,
      seeAsUser: (state) => state.login.seeAsUser,
      config: (state) => state.config.config,
    }),
    errorReporting: {
      get() {
        return this.$store.state.login.errorReporting;
      },
      set(v) {
        this.$store.commit('login/setErrorReporting', v);
      },
    },
  },
  watch: {
    changePasswordModalActive() {
      this.newPassword1 = '';
      this.newPassword2 = '';
      this.oldPassword = '';
      this.loading = false;
      this.error = undefined;
      this.additionnalUserInfo = deepClone(this.$store.state.login.userDetails.additionnal_info || {});
    },
  },
  methods: {
    async changePassword() {
      if (this.newPassword1 === this.newPassword2) {
        this.loading = true;
        try {
          const res = await Api.post('/changePassword', {
            newPassword: this.newPassword1, oldPassword: this.oldPassword,
          });
          this.newPassword1 = '';
          this.newPassword2 = '';
          this.oldPassword = '';
          this.loading = false;

          if (res.data.success) {
            this.changePasswordModalActive = false;
            Toast.open({
              message: i18n.password_changed,
              type: 'is-success',
            });
          } else if (res.data.error) {
            this.error = res.data.error;
          } else {
            this.error = i18n.unknown_error;
          }
        } catch (e) {
          this.error = e;
          console.log('error', e);
          this.loading = false;
        }
      } else {
        this.error = i18n.passwords_must_match;
      }
    },
    logout() {
      this.$store.dispatch('login/logout');
      // FIXME do not clear localstorage entirely
      localStorage.clear();
      redirect(this.$router, ['/login']);
    },
    async saveUserInfo() {
      const id = this.user._id;
      try {
        const res = await Api.post(`/users/${id}/additionnal_info`, this.additionnalUserInfo);
        const alreadyExistingUserDetails = this.$store.state.login.userDetails || {};
        this.$store.commit('login/setLoggedUserDetails', { ...alreadyExistingUserDetails, ...res.data.document });
        Toast.open({
          message: i18n.additionnal_info_changed,
          type: 'is-success',
        });
      } catch (e) {
        if (e.response.status) {
          Toast.open({
            message: i18n.error_user_not_found,
            type: 'is-danger',
          });
        } else {
          Toast.open({
            message: `${i18n.error_unknown} (${e.message})`,
            type: 'is-danger',
          });
        }
        console.log('err', e.response);
      }
    },
    stopSeeingAsUser() {
      this.$store.commit('login/setSeeAsUser', undefined);
    },
  },
};
</script>
<style scoped>
.user-buttons {
  font-weight: normal;
}
.control {
  position: relative;
}
button.main-menu-button {
  border-radius: 0;
  font-size: 75%;
  border-radius: 100px 0 0 100px;
}
.change-password-button {
  text-align: center;
}
.label {
  text-align: right;
  margin-right: 20px;
  margin-top: 10px;
}
table {
  width: 100%;
}

.loading {
  z-index: 100;
}
.stop-seeing-as-user-button {
  background: var(--primary-color-lighter);
  color: white;
}
.stop-seeing-as-user-button:hover {
  background: var(--primary-color);
  color: white;
}
.dropdown-footer {
  background: whitesmoke;
  font-size: 80%;
  padding: 6px;
  border-top: 1px solid rgb(10 10 10 / 10%);
  text-align: right;
  padding-right: 14px;
}
.user-buttons >>> .dropdown-content {
  padding-bottom: 0 !important;
}
</style>
